<template>
  <b-card-code>
    <validation-observer ref="simpleRules">
      <b-form @submit.prevent="submitForm">
        <b-row>
          <b-col md="12">
            <b-card-code title="Broker Details" no-body />
          </b-col>
          <b-col md="12">
            <div style="width: 100%; overflow: auto; min-height: 300px;">
              <table class="table table-bordered" style="min-width: 36cm">
                <thead class="text">
                  <tr>
                    <th style="width: 7cm">
                      <p style="text-align: center">
                        Broker Name<label style="color: red !important"
                          >*</label
                        >
                      </p>
                    </th>
                    <th style="width: 7cm">
                      <p style="text-align: center">Broker Mobile No</p>
                    </th>
                    <th style="width: 7cm">
                      <p style="text-align: center">Brokerage Type</p>
                    </th>
                    <th style="width: 7cm">
                      <p style="text-align: center">Type Calculation</p>
                    </th>
                    <th style="width: 7cm">
                      <p style="text-align: center">Brokerage Amount</p>
                    </th>
                    <th style="width: 1cm">
                      <p style="text-align: center">Action</p>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, id) in broker" :key="id">
                    <td>
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                        name="Broker Name"
                      >
                        <v-select
                          v-model="item.broker_id"
                          placeholder="None"
                          :options="brokerOption"
                          label="name"
                          @input="getNumber(id, $event)"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </td>
                    <td>
                      <b-form-input v-model="item.brokermobile" disabled />
                    </td>
                    <td>
                      <v-select
                        :options="['Fix', '%']"
                        v-model="item.brokeragetype"
                        placeholder="None"
                        @input="handleBrokrage(id)"
                      />
                    </td>
                    <td>
                      <b-form-input
                        v-model="item.typecalculation"
                        placeholder="Enter Type Calculation"
                        @input="handleBrokrage(id)"
                      />
                    </td>
                    <td>
                      <b-form-input
                        v-model="item.brokerageamount"
                        placeholder="Enter Brokarage Amount"
                        disabled
                      />
                    </td>
                    <td>
                      <b-button
                        variant="outline-danger"
                        class="mt-0"
                        @click="removebkRow(id)"
                      >
                        <feather-icon icon="TrashIcon" class="" />
                      </b-button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <b-button variant="primary" @click="addbkRow()" class="mb-1 mt-1">
              <feather-icon icon="PlusIcon" class="mr-25" />
              <span>Add More</span>
            </b-button>
          </b-col>

          <b-col md="12">
            <b-card-code title="Land Details" no-body />
          </b-col>
          <!-- <b-col md="12">
            <b-form-group label="Existing village ?">
              <b-form-radio-group
                id="radio-group-3"
                v-model="existing"
                name="radio-sub-component"
              >
                <b-form-radio name="some-radios" @click="clickRadio" value="Yes"
                  >Yes</b-form-radio
                >
                <b-form-radio name="some-radios" @click="clickRadio" value="No"
                  >No</b-form-radio
                >
              </b-form-radio-group>
            </b-form-group>
          </b-col> -->
          <!-- <b-col md="4" :style="existing == 'Yes' ? 'display:none' : ''">
            <b-form-group>
              <label>Village Name</label>
              <label style="color: red !important">*</label>
              <validation-provider
                #default="{ errors }"
                :rules="existing == 'Yes' ? '' : 'required'"
                name="Village Name"
              >
                <b-form-input
                  placeholder="Enter Village Name"
                  v-model="villagename"
                />

                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col> -->
          <b-col md="4">
            <b-form-group>
              <label>Village Name</label>
              <label style="color: red !important">*</label>
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Village Name"
              >
                <v-select
                  v-model="villagename"
                  placeholder="None"
                  label="villagename"
                  :options="villageOption"
                  @input="getVillageById($event)"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Survey No. New</label>
              <v-select
                v-model="surveynonew"
                placeholder="None"
                label="surveynonew"
                :options="newsurveynoOption"
                @input="surveyNo($event)"
              />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Survey No. Old</label>
              <b-form-input
                v-model="surveynoold"
                placeholder="Enter old Survay No"
                disabled
              />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>FP. No</label>

              <b-form-input v-model="fpno" disabled placeholder="Enter FP No" />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Original Plot No. (OP)</label>
              <b-form-input
                v-model="orignalplotno"
                disabled
                placeholder="Enter Original Plot No"
              />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Khata No</label>
              <b-form-input v-model="khatano" disabled placeholder="Khata No" />
            </b-form-group>
          </b-col>

          <b-col md="12">
            <b-card-code title="Farmer Details" no-body />
          </b-col>
          <b-col>
            <div style="width: 100%; overflow-x: auto; min-height: 300px;">
              <table class="table table-bordered" style="width: 36cm">
                <thead class="text">
                  <tr>
                    <th style="width: 7cm">
                      <p style="text-align: center">Farmer Name</p>
                    </th>
                    <th style="width: 7cm">
                      <p style="text-align: center">
                        Farmer father / Husband name
                      </p>
                    </th>
                    <th style="width: 7cm">
                      <p style="text-align: center">Aadhar card No</p>
                    </th>
                    <th style="width: 7cm">
                      <p style="text-align: center">Pan No</p>
                    </th>
                    <th style="width: 7cm">
                      <p style="text-align: center">Mobile</p>
                    </th>
                    <th style="width: 1cm">
                      <p style="text-align: center">Action</p>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, id) in farmer" :key="id">
                    <td>
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                        name="Farmer Name"
                      >
                        <v-select
                          v-model="item.farmer_id"
                          placeholder="None"
                          :options="farmerOption"
                          label="name"
                          @input="getFarmerDetail(id, $event)"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </td>
                    <td>
                      <b-form-input v-model="item.farmerhusbandname" disabled />
                    </td>
                    <td>
                      <b-form-input v-model="item.farmeraadharno" disabled />
                    </td>
                    <td>
                      <b-form-input
                        maxlength="10"
                        disabled
                        v-model="item.farmerpanno"
                      />
                    </td>
                    <td>
                      <b-form-input v-model="item.farmermobile" disabled />
                    </td>
                    <td>
                      <b-button
                        variant="outline-danger"
                        class="mt-0"
                        @click="removefrRow(id)"
                      >
                        <feather-icon icon="TrashIcon" class="" />
                      </b-button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <b-button variant="primary" @click="addfrRow()" class="mb-1 mt-1">
              <feather-icon icon="PlusIcon" class="mr-25" />
              <span>Add More</span>
            </b-button>
          </b-col>

          <b-col md="12">
            <b-card-code title="Buyer Details" no-body />
          </b-col>
          <b-col>
            <div style="width: 100%; overflow: auto; min-height: 300px;">
              <table class="table table-bordered" style="min-width: 36cm">
                <thead class="text">
                  <tr>
                    <th style="width: 7cm">
                      <p style="text-align: center">Buyer Name</p>
                    </th>
                    <th style="width: 7cm">
                      <p style="text-align: center">
                        Buyer father / Husband name
                      </p>
                    </th>
                    <th style="width: 7cm">
                      <p style="text-align: center">Aadhar card No</p>
                    </th>
                    <th style="width: 7cm">
                      <p style="text-align: center">Pan No</p>
                    </th>
                    <th style="width: 7cm">
                      <p style="text-align: center">Mobile</p>
                    </th>
                    <th style="width: 1cm">
                      <p style="text-align: center">Action</p>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, id) in buyer" :key="id">
                    <td>
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                        name="Buyer Name"
                      >
                        <v-select
                          v-model="item.buyer_id"
                          placeholder="None"
                          :options="buyerOption"
                          label="name"
                          @input="getBuyerDetail(id, $event)"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </td>
                    <td>
                      <b-form-input v-model="item.buyerhusbandname" disabled />
                    </td>
                    <td>
                      <b-form-input v-model="item.buyeraadharno" disabled />
                    </td>
                    <td>
                      <b-form-input
                        maxlength="10"
                        disabled
                        v-model="item.buyerpanno"
                      />
                    </td>
                    <td>
                      <b-form-input v-model="item.buyermobile" disabled />
                    </td>
                    <td>
                      <b-button
                        variant="outline-danger"
                        class="mt-0"
                        @click="removebrRow(id)"
                      >
                        <feather-icon icon="TrashIcon" class="" />
                      </b-button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <b-button variant="primary" @click="addbrRow()" class="mb-1 mt-1">
              <feather-icon icon="PlusIcon" class="mr-25" />
              <span>Add More</span>
            </b-button>
          </b-col>

          <b-col md="12">
            <b-card-code title="Purchasing Details" no-body />
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Property Type</label>
              <label style="color: red !important">*</label>
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Property Type"
              >
                <v-select
                  v-model="propertyType"
                  placeholder="None"
                  :options="['Bulk', 'Retail']"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Property in</label>
              <label style="color: red !important">*</label>
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Property in"
              >
                <v-select
                  v-model="propertyin"
                  placeholder="None"
                  :options="[
                    'Acre',
                    'Bigha',
                    'Sq.Yd',
                    'Sq.Mt',
                    'Sq.Ft',
                    'Unit',
                  ]"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4" :style="propertyType == 'Retail' ? '' : 'display:none'">
            <b-form-group>
              <label>Per Unit Type</label>
              <label style="color: red !important">*</label>
              <validation-provider
                #default="{ errors }"
                :rules="propertyType == 'Retail' ? 'required' : ''"
                name="Per Unit Type"
              >
                <v-select
                  v-model="plot"
                  placeholder="None"
                  :options="['Flat', 'Plot', 'Bungalow', 'Shop', 'Office']"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4" :style="propertyType == 'Retail' ? '' : 'display:none'">
            <b-form-group>
              <label>Quantity</label>
              <label style="color: red !important">*</label>
              <validation-provider
                #default="{ errors }"
                :rules="propertyType == 'Retail' ? 'required' : ''"
                name="Quantity"
              >
                <b-form-input
                  v-model="quantity"
                  placeholder=" Enter Quantity"
                  @input="handleValue()"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Total Area</label>
              <label style="color: red !important">*</label>
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Area"
              >
                <b-form-input
                  v-model="area"
                  @input="handleFP"
                  placeholder=" Enter Area"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="4" :style="propertyType == 'Bulk' ? '' : 'display:none'">
            <b-form-group>
              <label>Is FP?</label>
              <label style="color: red !important">*</label>
              <validation-provider
                #default="{ errors }"
                :rules="propertyType == 'Bulk' ? 'required' : ''"
                name="Fp"
              >
                <v-select
                  v-model="isfp"
                  placeholder="None"
                  :options="['Yes', 'No']"
                  @input="handleFP"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="4" :style="isfp == 'Yes' ? '' : 'display:none'">
            <b-form-group>
              <label>FP Area</label>
              <label style="color: red !important">*</label>
              <validation-provider
                #default="{ errors }"
                :rules="isfp == 'Yes' ? 'required' : ''"
                name="FP Area"
              >
                <b-form-input v-model="fparea" placeholder=" Enter FP Area" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="4" :style="propertyType == 'Retail' ? '' : 'display:none'">
            <b-form-group>
              <label>Rate</label>
              <label style="color: red !important">*</label>
              <validation-provider
                #default="{ errors }"
                :rules="propertyType == 'Retail' ? 'required' : ''"
                name="Rate"
              >
                <b-form-input
                  v-model="rate"
                  placeholder=" Enter Rate"
                  @input="handleValue()"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Total Value</label>
              <label style="color: red !important">*</label>
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Total Value"
              >
                <b-form-input
                  v-model="totalvalue"
                  placeholder=" Enter Total Value"
                  :disabled="propertyType == 'Retail' ? true : false"
                  @input="handleBrokerAmount"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4" :style="propertyType == 'Retail' ? '' : 'display:none'">
            <b-form-group>
              <label>Per {{ propertyin }}</label>
              <label style="color: red !important">*</label>
              <validation-provider
                #default="{ errors }"
                :rules="propertyType == 'Retail' ? 'required' : ''"
                name="Per Bigha"
              >
                <b-form-input
                  v-model="perbigha"
                  placeholder=" Enter Per Bigha"
                  @input="handleAmount()"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4" :style="propertyType == 'Retail' ? '' : 'display:none'">
            <b-form-group>
              <label>Total Amount</label>
              <label style="color: red !important">*</label>
              <validation-provider
                #default="{ errors }"
                :rules="propertyType == 'Retail' ? 'required' : ''"
                name="Total Amount"
              >
                <b-form-input
                  v-model="totalamount"
                  placeholder=" Enter Total Amount"
                  disabled
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>legal Expenses </label>
                <b-form-input
                  v-model="legalexpenses "
                  placeholder="Enter Legal Expenses"
                />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Registry Expenses</label>
                <b-form-input
                  v-model="registryexpenses"
                  placeholder="Enter Registry Expenses"
                />
            </b-form-group>
          </b-col> <b-col md="4">
            <b-form-group>
              <label>NA Expenses</label>
                <b-form-input
                  v-model="NAexpenses"
                  placeholder="Enter NA Expenses"
                />
            </b-form-group>
          </b-col> <b-col md="4">
            <b-form-group>
              <label>Other Payment</label>
                <b-form-input
                  v-model="otherpayment"
                  placeholder=" Enter Other Payment"
                />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Remarks</label>
              <b-form-textarea v-model="remarks" placeholder=" Enter Remarks" />
            </b-form-group>
          </b-col>

          <b-col md="12">
            <b-card-code title="Documentation" no-body />
          </b-col>
          <b-col md="12">
            <b-card-code title="Token Chiththi" no-body />
          </b-col>
          <b-col :md="tokenchitthi == 'Yes' ? '3' : '6'">
            <b-form-group>
              <label>Signed Token Chiththi</label>
              <label style="color: red !important">*</label>
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Signed Token Chiththi"
              >
                <v-select
                  v-model="tokenchitthi"
                  :options="['Yes', 'No']"
                  placeholder="None"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="3" v-if="tokenchitthi == 'Yes'">
            <b-form-group>
              <label>Upload File</label>
              <label style="color: red !important">*</label>
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="File"
              >
                <b-form-file
                  v-model="tokenchitthifile"
                  placeholder="Select Document"
                  drop-placeholder="Drop file here..."
                  @input="handleChangeFile($event, 'landpurchase', 'chiththi')"
                  accept="image/*"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <attachment :data="tokenchitthifile" />
          </b-col>
          <b-col :md="tokenchitthireceived == 'Yes' ? '3' : '6'">
            <b-form-group>
              <label>Received</label>
              <label style="color: red !important">*</label>
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Received"
              >
                <v-select
                  v-model="tokenchitthireceived"
                  :options="['Yes', 'No']"
                  placeholder="None"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="3" v-if="tokenchitthireceived == 'Yes'">
            <b-form-group>
              <label>Upload File</label>
              <label style="color: red !important">*</label>
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="File"
              >
                <b-form-file
                  v-model="tokenchitthireceivedfile"
                  placeholder="Select Document"
                  drop-placeholder="Drop file here..."
                  @input="handleChangeFile($event, 'landpurchase', 'received')"
                  accept="image/*"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <attachment :data="tokenchitthireceivedfile" />
          </b-col>

          <b-col md="12">
            <b-row>
              <b-card-code title="Title Clear" no-body />
            </b-row>
            <b-row>
              <b-col :md="farmerapplication == 'Yes' ? '3' : '4'">
                <b-form-group>
                  <label style="font-size: 9.5px"
                    >Farmer Application for Papernote/Jaher suchna</label
                  >
                  <label style="color: red !important">*</label>
                  <validation-provider
                    #default="{ errors }"
                    rules="required"
                    name="Farmer Application"
                  >
                    <v-select
                      v-model="farmerapplication"
                      :options="['Yes', 'No']"
                      placeholder="None"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="3" v-if="farmerapplication == 'Yes'">
                <b-form-group>
                  <label>Select Date</label>
                  <label style="color: red !important">*</label>
                  <validation-provider
                    #default="{ errors }"
                    rules="required"
                    name="farmer date"
                  >
                    <flat-pickr
                      class="form-control"
                      value=""
                      v-model="farmerapplicationdate"
                      placeholder="Select Date"
                      :config="{
                        dateFormat: 'd/m/Y',
                        maxDate: new Date(),
                      }"
                      style="background-color: transparent"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="3" v-if="farmerapplication == 'Yes'">
                <b-form-group>
                  <label>Upload File</label>
                  <label style="color: red !important">*</label>
                  <validation-provider
                    #default="{ errors }"
                    rules="required"
                    name=" upload File"
                  >
                    <b-form-file
                      v-model="farmerapplicationupload"
                      placeholder="Select Document"
                      drop-placeholder="Drop file here..."
                      @input="
                        handleChangeFile(
                          $event,
                          'landpurchase',
                          'farmerapplicationupload'
                        )
                      "
                      accept="image/*"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <attachment :data="farmerapplicationupload" />
              </b-col>
              <b-col md="3" v-if="farmerapplication == 'Yes'">
                <b-form-group>
                  <label>Remark</label>

                  <b-form-textarea
                    v-model="farmerapplicationremarks"
                    placeholder=" Enter Remarks"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
          <b-col md="12">
            <b-row>
              <b-col :md="papernote == 'Yes' ? '3' : '4'">
                <b-form-group>
                  <label>Paper Note/Jaher Suchna</label>
                  <label style="color: red !important">*</label>
                  <validation-provider
                    #default="{ errors }"
                    rules="required"
                    name="Paper Note/Jaher Suchna"
                  >
                    <v-select
                      v-model="papernote"
                      :options="['Yes', 'No']"
                      placeholder="None"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="3" v-if="papernote == 'Yes'">
                <b-form-group>
                  <label>Select Date</label>
                  <label style="color: red !important">*</label>
                  <validation-provider
                    #default="{ errors }"
                    rules="required"
                    name="paper date"
                  >
                    <flat-pickr
                      class="form-control"
                      value=""
                      v-model="papernotedate"
                      placeholder="Select Date"
                      :config="{
                        dateFormat: 'd/m/Y',
                        maxDate: new Date(),
                      }"
                      style="background-color: transparent"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="3" v-if="papernote == 'Yes'">
                <b-form-group>
                  <label>Upload File</label>
                  <label style="color: red !important">*</label>
                  <validation-provider
                    #default="{ errors }"
                    rules="required"
                    name=" upload File"
                  >
                    <b-form-file
                      v-model="papernoteupload"
                      placeholder="Select Document"
                      drop-placeholder="Drop file here..."
                      @input="
                        handleChangeFile(
                          $event,
                          'landpurchase',
                          'papernoteupload'
                        )
                      "
                      accept="image/*"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <attachment :data="papernoteupload" />
              </b-col>
              <b-col md="3" v-if="papernote == 'Yes'">
                <b-form-group>
                  <label>Remark</label>
                  <b-form-textarea
                    v-model="papernoteremarks"
                    placeholder=" Enter Remarks"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>

          <b-col :md="titleclear == 'Yes' ? '3' : '4'">
            <b-form-group>
              <label>Title Clear/NOC</label>
              <label style="color: red !important">*</label>
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Title Clear/Jaher Suchan NOC"
              >
                <v-select
                  v-model="titleclear"
                  :options="['Yes', 'No']"
                  placeholder="None"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="3" v-if="titleclear == 'Yes'">
            <b-form-group>
              <label>Select Date</label>
              <label style="color: red !important">*</label>
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Title date"
              >
                <flat-pickr
                  class="form-control"
                  value=""
                  v-model="titlecleardate"
                  placeholder="Select Date"
                  :config="{
                    dateFormat: 'd/m/Y',
                    maxDate: new Date(),
                  }"
                  style="background-color: transparent"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="3" v-if="titleclear == 'Yes'">
            <b-form-group>
              <label>Upload File</label>
              <label style="color: red !important">*</label>
              <validation-provider
                #default="{ errors }"
                rules="required"
                name=" upload File"
              >
                <b-form-file
                  v-model="titleclearupload"
                  placeholder="Select Document"
                  drop-placeholder="Drop file here..."
                  @input="
                    handleChangeFile($event, 'landpurchase', 'titleclearupload')
                  "
                  accept="image/*"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <attachment :data="titleclearupload" />
          </b-col>
          <b-col md="3" v-if="titleclear == 'Yes'">
            <b-form-group>
              <label>Remark</label>
              <b-form-textarea
                v-model="titleclearremarks"
                placeholder=" Enter Remarks"
              />
            </b-form-group>
          </b-col>

          <b-col md="12">
            <b-card-code title="NA Process" no-body />
          </b-col>
          <b-col>
            <div style="width: 100%; overflow: auto">
              <table class="table table-bordered" style="width: 36cm">
                <thead class="text">
                  <tr>
                    <th style="width: 5">
                      <p style="text-align: center">Activity</p>
                    </th>
                    <th style="width: 5">
                      <p style="text-align: center">Upload File</p>
                    </th>
                    <th style="width: 5">
                      <p style="text-align: center">Pending</p>
                    </th>
                    <th style="width: 5">
                      <p style="text-align: center">Remark</p>
                    </th>
                    <th style="width: 1cm">
                      <p style="text-align: center">Action</p>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, id) in naprocess" :key="id">
                    <td>
                      <b-form-input
                        v-model="item.naactivity"
                        placeholder="Enter Activity"
                      />
                    </td>
                    <td>
                      <b-form-file
                        v-model="item.nauploadfile"
                        placeholder="Select Document"
                        drop-placeholder="Drop file here..."
                        @input="
                          handleChangeFile(
                            $event,
                            'landpurchase',
                            'naprocess',
                            id
                          )
                        "
                        accept="image/*"
                      />
                      <attachment :data="item.nauploadfile" />
                    </td>
                    <td>
                      <b-form-input
                        v-model="item.napending"
                        placeholder="Enter Pending"
                      />
                    </td>
                    <td>
                      <b-form-input
                        v-model="item.naremarks"
                        placeholder="Enter Remarks"
                      />
                    </td>
                    <td>
                      <b-button
                        variant="outline-danger"
                        class="mt-0"
                        @click="removenaRow(id)"
                      >
                        <feather-icon icon="TrashIcon" class="" />
                      </b-button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <b-button variant="primary" @click="addnaRow()" class="mb-1 mt-1">
              <feather-icon icon="PlusIcon" class="mr-25" />
              <span>Add More</span>
            </b-button>
          </b-col>

          <b-col md="12">
            <b-card-code title="Sale Deed " no-body />
          </b-col>

          <b-col>
            <div style="width: 100%; overflow: auto">
              <table class="table table-bordered" style="width: 28.5cm">
                <thead class="text">
                  <tr>
                    <th></th>
                    <th>Documentation</th>
                    <th>Remarks</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody
                  style="border: inset; border-color: #dddddd"
                  v-for="(item, id) in saledeed"
                  :key="id"
                >
                  <tr>
                    <td rowspan="3">{{ id + 1 }}</td>
                    <td>
                      <b-form-input
                        v-model="item.documentation"
                        placeholder="Enter Documentation"
                      />
                    </td>
                    <td>
                      <b-form-input
                        v-model="item.documentationremarks"
                        placeholder="Enter Remarks"
                      />
                    </td>
                    <td rowspan="3">
                      <b-button
                        variant="outline-danger"
                        class="mt-0"
                        @click="removesdRow(id)"
                      >
                        <feather-icon icon="TrashIcon" class="" />
                      </b-button>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b-form-input
                        v-model="item.processtracking"
                        placeholder="Enter Processing Tracking"
                      />
                    </td>
                    <td>
                      <b-form-input
                        v-model="item.processtrackingremarks"
                        placeholder="Enter Remarks"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b-form-input
                        v-model="item.finalbuyername"
                        placeholder="Enter Final Buyer Name"
                      />
                    </td>
                    <td>
                      <b-form-input
                        v-model="item.finalbuyerremarks"
                        placeholder="Enter Remarks"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <b-button variant="primary" @click="addsdRow()" class="mb-1 mt-1">
              <feather-icon icon="PlusIcon" class="mr-25" />
              <span>Add More</span>
            </b-button>
          </b-col>

          <!-- <b-col md="4">
            <b-form-group>
              <label>For Sale?</label>
              <label style="color: red !important">*</label>
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="For Sale"
              >
                <v-select
                  v-model="forsale"
                  :options="['Yes', 'No']"
                  placeholder="None"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col> -->
          <!-- <b-col v-if="forsale == 'Yes'" md="4">
            <b-form-group>
              <label>With NA?</label>
              <label style="color: red !important">*</label>
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="With NA"
              >
                <v-select
                  v-model="withna"
                  :options="['Yes', 'No']"
                  placeholder="None"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col v-if="withna == 'Yes'" md="4">
            <b-form-group>
              <label>NA Charges</label>
              <small>(Per Bigha)</small>
              <label style="color: red !important">*</label>
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="NA Charges"
              >
                <b-form-input
                  v-model="nacharges"
                  placeholder="Enter NA Charges"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col v-if="forsale == 'Yes'" md="4">
            <b-form-group>
              <label>Owner's Rate</label>
              <label style="color: red !important">*</label>
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Farmer Application"
              >
                <b-form-input
                  v-model="ownerrate"
                  placeholder="Enter Owner's Rate"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col v-if="forsale == 'Yes'" md="4">
            <b-form-group>
              <label>CP final Rate</label>
              <label style="color: red !important">*</label>
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Cp Rate"
              >
                <b-form-input
                  v-model="cprate"
                  placeholder="Enter CP final Rate"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col v-if="forsale == 'Yes'" md="4">
            <b-form-group>
              <label>Market Rate</label>
              <label style="color: red !important">*</label>
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Market Rate"
              >
                <b-form-input
                  v-model="marketrate"
                  placeholder="Enter Market Rate"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col> -->

          <!-- <b-col md="4" v-if="forsale == 'Yes'">
            <label>Assign To</label>
            <b-form-group style="margin-top: 7px">
              <b-form-checkbox v-model="cp" @input="handleCpFr($event)" inline>
                Channel Partner
              </b-form-checkbox>
              <b-form-checkbox v-model="fr" @input="handleCpFr($event)" inline>
                Franchise
              </b-form-checkbox>
            </b-form-group>
          </b-col>
          <b-col md="4" v-if="forsale == 'Yes'">
            <b-form-group>
              <label>Select CP/Franchise</label>
              <v-select
                v-model="assignto"
                placeholder="None"
                :options="assignToOption"
                @input="handleAll($event)"
                multiple
                label="name"
              />
            </b-form-group>
          </b-col> -->

          <b-col cols="12">
            <b-button
              variant="primary"
              :disabled="flag || submitDisable"
              type="submit"
              @click.prevent="submitForm"
            >
              Submit
            </b-button>
            <b-button class="ml-1" variant="primary" @click="onClickBack"
              >Back
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card-code>
</template>
<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import BCardCode from "@core/components/b-card-code";
import PinchScrollZoom from "@coddicat/vue-pinch-scroll-zoom";
import {
  BFormInput,
  BFormFile,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton,
  BFormTextarea,
  BFormDatepicker,
  BImgLazy,
  BTable,
  BFormRadio,
  BFormRadioGroup,
  BFormCheckbox,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Datepicker from "vuejs-datepicker";
import flatPickr from "vue-flatpickr-component";
import axios from '@/components/axios';
import Attachment from "../../../components/Attechment.vue";
import moment from "moment";

export default {
  components: {
    BFormRadio,
    BFormCheckbox,

    BFormRadioGroup,
    flatPickr,
    PinchScrollZoom,
    Datepicker,
    BFormFile,
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    vSelect,
    BFormDatepicker,
    BFormTextarea,
    BImgLazy,
    BTable,
    Attachment,
  },
  data() {
    return {
      isfp: "",
      fparea: "",

      forsale: "",
      withna: "",
      cprate: "",
      ownerrate: "",
      marketrate: "",
      nacharges: "",

      assignto: [],
      subassignto: [],

      broker: [
        {
          brokername: "",
          broker_id: "",
          brokermobile: "",
          brokeragetype: "",
          typecalculation: 0,
          brokerageamount: 0,
        },
      ],
      brokerOption: [],

      villagename: "",
      surveynoold: "",
      surveynonew: "",
      fpno: "",
      khatano: "",
      orignalplotno: "",

      farmer: [
        {
          farmer_id: "",
          farmerhusbandname: "",
          farmeraadharno: "",
          farmerpanno: "",
          farmermobile: "",
        },
      ],
      farmerOption: [],

      buyer: [
        {
          buyer_id: "",
          buyername: "",
          buyerhusbandname: "",
          buyeraadharno: "",
          buyerpanno: "",
          buyermobile: "",
        },
      ],
      buyerOption: [],
      propertyType: "",
      propertyin: "",
      plot: "",
      quantity: 0,
      area: "",
      rate: 0,
      totalvalue: "",
      perbigha: "",
      totalamount: "",
      otherpayment: "",
      remarks: "",

      tokenchitthi: "",
      tokenchitthifile: "",
      tokenchitthireceived: "",
      tokenchitthireceivedfile: "",

      farmerapplication: "",
      farmerapplicationdate: moment(new Date()).format("DD/MM/yyyy"),
      farmerapplicationupload: "",
      farmerapplicationremarks: "",
      papernote: "",
      papernotedate: moment(new Date()).format("DD/MM/yyyy"),
      papernoteupload: "",
      papernoteremarks: "",
      titleclear: "",
      titlecleardate: moment(new Date()).format("DD/MM/yyyy"),
      titleclearupload: "",
      titleclearremarks: "",

      naprocess: [
        {
          naactivity: "",
          nauploadfile: "",
          napending: "",
          naremarks: "",
        },
      ],
      saledeed: [
        {
          documentation: "",
          documentationremarks: "",
          processtracking: "",
          processingtrackingremarks: "",
          finalbuyername: "",
          finalbuyerremarks: "",
        },
      ],

      testbuyer: [],
      testfarmer: [],
      testbroker: [],
      allVillageOption: [],
      villageOption: [],
      baseApi: process.env.VUE_APP_APIENDPOINT,
      accessToken: localStorage.getItem("accessToken"),

      flag: false,
      existing: "No",
      submitDisable: false,
      newsurveynoOption: [],

      allFrUser: [],
      allCpUser: [],
      cp: false,
      fr: false,
      allcp: [],
      allfr: [],
      assignToOption: [],
      assignto11: [],
      test: [],
      userId: "",
      resObj:{},
legalexpenses:"",
registryexpenses:"",
NAexpenses:"",
    };
  },
  async mounted() {
    const userData = JSON.parse(localStorage.getItem("UserRights"));
    const data = JSON.parse(localStorage.getItem("userData"));
    const role = data.role;
    this.userId = data.id;

    if (role !== "admin") {
      userData.map((item) => {
        if (item.modulename == "Land Purchase") {
          if (this.$route.params.id) {
            if (item.edit !== 1) {
              this.$router.push("/accounting/landpurchase");
            }
          } else if (item.add !== 1) {
            this.$router.push("/accounting/landpurchase");
          }
        }
      });
    }
    await this.getVillage();
    // await this.getCP();
    // await this.getFranchise();

    this.init();
    this.ifEdit = !!this.$route.params.id;
    this.ifEdit && this.getEditValue();
  },
  methods: {
    handleFP() {
      if (this.isfp == "Yes") {
        this.fparea = this.area / 2;
      }
    },
    // handleAssignto() {
    //   let all = [...this.allCpUser, ...this.allFrUser];
    //   all.map((item) => {
    //     this.assignto.map((inner, index) => {
    //       if (inner == item.id) {
    //         this.assignto[index] = item;
    //       }
    //     });
    //   });
    // },
    // handleCpFr() {
    //   if (this.cp == false && this.fr == true) {
    //     this.assignToOption = [];

    //     this.assignToOption.push({ name: "All" });
    //     this.assignToOption = [...this.assignToOption, ...this.allfr];
    //   } else if (this.cp == true && this.fr == false) {
    //     this.assignToOption = [];

    //     this.assignToOption.push({ name: "All" });
    //     this.assignToOption = [...this.assignToOption, ...this.allcp];
    //   } else if (this.cp == true && this.fr == true) {
    //     this.assignToOption = [];

    //     this.assignToOption.push({ name: "All" });
    //     this.assignToOption = [
    //       ...this.assignToOption,
    //       ...this.allfr,
    //       ...this.allcp,
    //     ];
    //   }
    //   if (this.assignToOption.length == 1) {
    //     this.assignToOption = [];
    //   }
    // },
    // handleAll(e) {
    //   if (e) {
    //     if (e[e.length - 1].name == "All") {
    //       this.assignto = this.assignToOption;
    //       this.assignto.shift();
    //     }
    //   }
    // },
    // handleDownlineCp(id) {
    //   console.log("first");

    //   this.allCpUser.map((item) => {
    //     if (item.parent == id && !this.test[item.id]) {
    //       this.subassignto.push(item.id);
    //       this.test[item.id] = item.id;
    //       this.handleDownlineCp(item.id);
    //     }
    //   });
    // },
    // handleDownlineFr(id) {
    //   console.log("first");
    //   this.allFrUser.map((item) => {
    //     if (item.parent == id) {
    //       this.subassignto.push(item.id);
    //       this.test[item.id] = item.id;

    //       this.handleDownlineFr(item.id);
    //     }
    //   });
    // },
    // handleChildren() {
    //   this.assignto11.map((item) => {
    //     console.log(item.id);
    //     this.subassignto.push(item.id);
    //     if (item.role == "channelpartner") {
    //       this.handleDownlineCp(item.id);
    //     } else {
    //       this.handleDownlineFr(item.id);
    //     }
    //   });
    // },
    // async getFranchise() {
    //   const requestOptions = {
    //     method: "GET",
    //     headers: {
    //       "Content-Type": "application/json",
    //       Authorization: `Bearer ${this.accessToken}`,
    //     },
    //     url: `${this.baseApi}/getFranchise`,
    //   };
    //   await axios(requestOptions)
    //     .then((response) => {
    //       this.allFrUser = response.data.data;
    //       response.data.data.map((item) => {
    //         if (item.parent == this.userId) {
    //           this.allfr.push(item);
    //         }
    //       });
    //     })
    //     .catch((error) => console.log(error, "error"));
    // },
    // async getCP() {
    //   const requestOptions = {
    //     method: "GET",
    //     headers: {
    //       "Content-Type": "application/json",
    //       Authorization: `Bearer ${this.accessToken}`,
    //     },
    //     url: `${this.baseApi}/getChannelpartner`,
    //   };
    //   await axios(requestOptions)
    //     .then((response) => {
    //       this.allCpUser = response.data.data;
    //       response.data.data.map((item) => {
    //         if (item.parent == this.userId) {
    //           this.allcp.push(item);
    //         }
    //       });
    //     })
    //     .catch((error) => console.log(error, "error"));
    // },
    // clickRadio() {
    //   this.villagename = "";
    // },
    // async getVillage() {
    //   const requestOptions = {
    //     method: "GET",
    //     headers: {
    //       "Content-Type": "application/json",
    //       Authorization: `Bearer ${this.accessToken}`,
    //     },
    //     url: `${this.baseApi}/getvillagedata`,
    //   };

    //   await axios(requestOptions).then((response) => {
    //     this.allVillageOption = response.data.data;
    // const resArr = [];
    // this.allVillageOption.filter(function (item) {
    //   const i = resArr.findIndex((x) => x.villagename == item.villagename);
    //   if (i <= -1) {
    //     resArr.push(item);
    //   }
    //   return null;
    // });
    // this.allVillageOption = resArr;
    //   });
    // },
    getVillageById(e) {
      if (e) {
        this.newsurveynoOption = [];
        this.allVillageOption.map((item) => {
          if (item.villagename == this.villagename.villagename) {
            this.newsurveynoOption.push(item);
          }
        });
      }

      // this.allVillageOption.map((item) => {
      //   if (id == item.id) {
      //     this.surveynoold = item.surveynoold;
      //     this.surveynonew = item.surveynonew;
      //     this.fpno = item.fpno;
      //     this.khatano = item.khatano;
      //     this.orignalplotno = item.orignalplotno;
      //   }
      // });
    },
    surveyNo(e) {
      if (e) {
        // this.newsurveyno = e.surveynonew;
        // this.accountno = e.khatano;
        // this.opno = e.orignalplotno;
        // this.fpno = e.fpno;

        this.surveynoold = e.surveynoold;
        this.fpno = e.fpno;
        this.khatano = e.khatano;
        this.orignalplotno = e.orignalplotno;
      }
    },
    async getVillage(id) {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/landmaster?pagesize=10000&pagenum=1`,
      };
      await axios(requestOptions)
        .then((response) => {
          this.allVillageOption = response.data.data.data;
          this.resObj = {};
          this.allVillageOption.map((item)=>{
            this.resObj[item.villagename] =item
          })
          // this.allVillageOption.filter(function (item) {
          //   const i = resArr.findIndex(
          //     (x) => x.villagename == item.villagename
          //   );
          //   if (i <= -1 && item.is_purchase == 0 && item.is_sales == 0) {
          //     resArr.push(item);
          //   }
          //   return null;
          // });

          this.villageOption = Object.values(this.resObj);
        })
        .catch((error) => console.log(error, "error"));
    },
    async handleChangeFile(e, type, name, index) {
      this.flag = true;
      const formData = new FormData();
      formData.append("image", e);
      formData.append("type", type);

      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${this.accessToken}`,
        },
        data: formData,
        url: `${this.baseApi}/attachment`,
      };

      await axios(requestOptions)
        .then((response) => {
          if (name == "naprocess") {
            this.naprocess[index].nauploadfile =  process.env.VUE_APP_IMAGE_PATH + response.data.attachment;
          } else if (name == "chiththi") {
            this.tokenchitthifile =  process.env.VUE_APP_IMAGE_PATH + response.data.attachment;
          } else if (name == "received") {
            this.tokenchitthireceivedfile =  process.env.VUE_APP_IMAGE_PATH + response.data.attachment;
          } else if (name == "farmerapplicationupload") {
            this.farmerapplicationupload =  process.env.VUE_APP_IMAGE_PATH + response.data.attachment;
          } else if (name == "papernoteupload") {
            this.papernoteupload =  process.env.VUE_APP_IMAGE_PATH + response.data.attachment;
          } else if (name == "titleclearupload") {
            this.titleclearupload =  process.env.VUE_APP_IMAGE_PATH + response.data.attachment;
          }
          this.flag = false;
        })
        .catch((error) => console.log(error, "error"));
    },
    async getFarmerDetail(id, e) {
      await axios({
        method: "GET",
        url: `${this.baseApi}/getfarmerById/${e.id}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
      })
        .then((json) => {
          // this.farmer[id].farmerfathernmae = json.data.data[0].farmername
          this.farmer[id].farmeraadharno = json.data.data[0].aadharcardno;
          this.farmer[id].farmermobile = json.data.data[0].mobile;
          this.farmer[id].farmerhusbandname = json.data.data[0].fathername;
          this.farmer[id].farmerpanno = json.data.data[0].panno;
        })
        .catch((error) => console.log(error, "error"));
    },
    async getBuyerDetail(id, e) {
      await axios({
        method: "GET",
        url: `${this.baseApi}/getbuyerById/${e.id}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
      })
        .then((json) => {
          this.buyer[id].buyeraadharno = json.data.data[0].aadharcardno;
          this.buyer[id].buyermobile = json.data.data[0].mobile;
          this.buyer[id].buyerhusbandname = json.data.data[0].fathername;
          this.buyer[id].buyerpanno = json.data.data[0].panno;
        })
        .catch((error) => console.log(error, "error"));
    },
    async getNumber(id, e) {
      await axios({
        method: "GET",
        url: `${this.baseApi}/getbrokerById/${e.id}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
      })
        .then((json) => {
          this.broker[id].brokermobile = json.data.data[0].mobile;
        })
        .catch((error) => console.log(error, "error"));
    },
    handelchangeShowData(id) {
      if (this.showHide[id] == null) {
        this.showHide[id] = 0;
      }
      this.showHide[id] == 0 ? 1 : 0;
      this.$forceUpdate();
    },
    handlevillageAtEdit(id) {
      this.allVillageOption.map((item) => {
        if (item.id == id) {
          this.resObj[item.villagename]=item
          this.villagename = item;
          this.surveynonew = item;
          this.getVillageById(this.villagename);
        }
      });
      this.villageOption = Object.values(this.resObj)
      // this.villageOption.map((item, index) => {
      //   if (item.name == this.villagename.village_name) {
      //     this.villageOption[index] = this.villagename;
      //   }
      // });
    },

    async getEditValue() {
      // console.log(this.$route.params.id, "id====");
      await axios({
        method: "GET",
        url: `${this.baseApi}/getlandpurchaseById/${this.$route.params.id}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
      })
        .then((json) => {
          this.getEditItems(json.data.data);
        })
        .catch((error) => console.log(error, "error"));
    },
    getEditItems(item) {
      this.farmer = [];
      this.buyer = [];
      this.broker = [];
      item.map((item) => {
        this.handlevillageAtEdit(item.villagename);

        this.surveynoold = item.surveynoold;
        this.fpno = item.fpno;
        this.khatano = item.khatano;
        this.orignalplotno = item.orignalplotno;
        this.propertyin = item.propertyin;
        this.propertyType = item.propertyType;
        this.plot = item.plot;
        this.quantity = item.quantity;
        this.area = item.area;
        this.rate = item.rate;
        this.totalvalue = item.totalvalue;
        this.perbigha = item.perbigha;
        this.totalamount = item.totalamount;
        this.otherpayment = item.otherpayment;
        this.remarks = item.remarks;
        this.tokenchitthi = item.tokenchitthi;
        this.tokenchitthifile = item.tokenchitthifile;
        this.tokenchitthireceived = item.tokenchitthireceived;
        this.tokenchitthireceivedfile = item.tokenchitthireceivedfile;
        this.farmerapplication = item.farmerapplication;
        this.farmerapplicationdate = item.farmerapplicationdate;
        this.farmerapplicationupload = item.farmerapplicationupload;
        this.farmerapplicationremarks = item.farmerapplicationremarks;
        this.papernote = item.papernote;
        this.papernotedate = item.papernotedate;
        this.papernoteupload = item.papernoteupload;
        this.papernoteremarks = item.papernoteremarks;
        this.titleclear = item.titleclear;
        this.titlecleardate = item.titlecleardate;
        this.titleclearupload = item.titleclearupload;
        this.titleclearremarks = item.titleclearremarks;
        this.isfp = item.isfp;
        this.fparea = item.fparea;
this.legalexpenses=item.legalexpenses
this.registryexpenses=item.registryexpenses
this.NAexpenses=item.NAexpenses
        // this.forsale = item.forsale;
        // this.withna = item.withna;
        // this.cprate = item.cprate;
        // this.ownerrate = item.ownerrate;
        // this.marketrate = item.marketrate;
        // this.nacharges = item.nacharges;
        // this.assignto = item.assignto;
        // this.subassignto = item.subassignto;
        this.testfarmer = item.farmer;
        this.testfarmer.map((item, index) => {
          this.farmer.push({
            farmer_id: item.farmername,
            farmername: item.farmer_id,
            farmerhusbandname: item.farmerhusbandname,
            farmeraadharno: item.farmeraadharno,
            farmerpanno: item.farmerpanno,
            farmermobile: item.farmermobile,
          });
        });
        this.testbuyer = item.buyer;
        this.testbuyer.map((item, index) => {
          this.buyer.push({
            buyer_id: item.buyername,
            buyername: item.buyer_id,
            buyerhusbandname: item.buyerhusbandname,
            buyeraadharno: item.buyeraadharno,
            buyerpanno: item.buyerpanno,
            buyermobile: item.buyermobile,
          });
        });
        this.testbroker = item.broker;
        this.testbroker.map((item, index) => {
          this.broker.push({
            broker_id: item.brokername,
            brokername: item.broker_id,
            brokermobile: item.brokermobile,
            brokeragetype: item.brokeragetype,
            typecalculation: item.typecalculation,
            brokerageamount: item.brokerageamount,
          });
        });
        this.naprocess = item.naprocess;
        this.saledeed = item.saledeed;

        // this.handleAssignto();
      });
    },

    submitForm(e) {
      this.$refs.simpleRules.validate().then(async (success) => {
        let data;
        if (success == true) {
          this.handlefarmer();
          this.handlebuyer();
          this.handlebroker();
          // this.assignto11 = [...this.assignto];
          // this.subassignto = [];
          // this.test = {};
          // this.handleChildren();
          // this.assignto11.map((item, index) => {
          //   this.assignto11[index] = item.id;
          // });
          // this.subassignto = this.subassignto.toString();
          data = {
            villagename: this.surveynonew?this.surveynonew.id:this.villagename.id,
            surveynoold: this.surveynoold,
            surveynonew: this.surveynonew.surveynonew,
            fpno: this.fpno,
            khatano: this.khatano,
            orignalplotno: this.orignalplotno,
            propertyin: this.propertyin,
            propertyType: this.propertyType,
            quantity: this.quantity,
            area: this.area,
            plot: this.plot,
            rate: this.rate,
            totalvalue: this.totalvalue,
            perbigha: this.perbigha,
            totalamount: this.totalamount,
            otherpayment: this.otherpayment,
            remarks: this.remarks,
            tokenchitthi: this.tokenchitthi,
            tokenchitthifile: this.tokenchitthifile,
            tokenchitthireceived: this.tokenchitthireceived,
            tokenchitthireceivedfile: this.tokenchitthireceivedfile,
            farmerapplication: this.farmerapplication,
            farmerapplicationdate: this.farmerapplicationdate,
            farmerapplicationupload: this.farmerapplicationupload,
            farmerapplicationremarks: this.farmerapplicationremarks,
            papernote: this.papernote,
            papernotedate: this.papernotedate,
            papernoteupload: this.papernoteupload,
            papernoteremarks: this.papernoteremarks,
            titleclear: this.titleclear,
            titlecleardate: this.titlecleardate,
            titleclearupload: this.titleclearupload,
            titleclearremarks: this.titleclearremarks,
            broker: this.broker,
            farmer: this.farmer,
            buyer: this.buyer,
            naprocess: this.naprocess,
            saledeed: this.saledeed,
            legalexpenses:this.legalexpenses,
            registryexpenses:this.registryexpenses,
            NAexpenses:this.NAexpenses,
            isfp: this.isfp,
            fparea: this.fparea,
            // forsale: this.forsale,
            // withna: this.withna,
            // cprate: this.cprate,
            // ownerrate: this.ownerrate,
            // marketrate: this.marketrate,
            // nacharges: this.nacharges,
            // assignto: this.assignto11,
            // subassignto: this.subassignto,
          };
        }

        if (
          success == false ||
          this.broker.length == 0 ||
          this.farmer.length == 0 ||
          this.buyer.length == 0
        ) {
          this.check = success;
          this.$swal({
            title: "Warning",
            timer: 5000,
            text: "Plese Fill the Required Field",
            icon: "warning",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        }

        if (success) {
          this.submitDisable = true;

          const accessToken = localStorage.getItem("accessToken");
          const baseApi = process.env.VUE_APP_APIENDPOINT;
          await axios({
            method: `${this.ifEdit ? "put" : "post"}`,
            url: this.ifEdit
              ? `${baseApi}/landpurchase/${this.$route.params.id}`
              : `${baseApi}/landpurchase`,
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${accessToken}`,
            },
            data: JSON.stringify(data),
          })
            .then((json) => {
              this.submitDisable = false;

              if (json.data.status == 200 || json.data) {
                this.$swal({
                  title: "Submited",
                  text: json.data.message
                    ? `${json.data.message}`
                    : "Update SuccessFully",
                  icon: "success",
                  timer: 5000,
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                  buttonsStyling: false,
                });
              }
              this.$router.push("/accounting/landpurchase");
            })
            .catch((error) => {
              this.submitDisable = false;

              this.$swal({
                title: "Error!",
                text: `${error}`,
                icon: "error",
                timer: 5000,
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },

    onClickBack() {
      this.$router.push("/accounting/landpurchase");
    },
    handlefarmer() {
      this.farmer.map((item) => {
        if (item.farmer_id.id) {
          const x = item.farmer_id;
          item.farmer_id = x.id;
          item.farmername = x.name;
        } else if (isNaN(item.farmer_id)) {
          const y = item.farmer_id;
          item.farmer_id = item.farmername;
          item.farmername = y;
        }
      });
    },
    handlebuyer() {
      this.buyer.map((item) => {
        if (item.buyer_id.id) {
          const x = item.buyer_id;
          item.buyer_id = x.id;
          item.buyername = x.name;
        } else if (isNaN(item.buyer_id)) {
          const y = item.buyer_id;
          item.buyer_id = item.buyername;
          item.buyername = y;
        }
      });
    },
    handlebroker() {
      this.broker.map((item) => {
        if (item.broker_id.id) {
          const x = item.broker_id;
          item.broker_id = x.id;
          item.brokername = x.name;
        } else if (isNaN(item.broker_id)) {
          const y = item.broker_id;
          item.broker_id = item.brokername;
          item.brokername = y;
        }
      });
    },
    // handleDetails() {
    //   if (this.details.length == 0) {
    //     this.$toast({
    //       component: ToastificationContent,
    //       position: 'top-right',
    //       props: {
    //         variant: 'danger',
    //         text: 'Please Enter Payment Information '
    //       }
    //     })
    //   } else {

    //     this.details.map((item) => {
    //       if (!item.projectname) {
    //         item.project_id = item.project_id.id
    //       } else if (item.project_id.id) {
    //         const x = item.project_id
    //         item.project_id = x.id
    //         item.projectname = x.name
    //       } else {
    //         const y = item.project_id
    //         item.project_id = item.projectname
    //         item.projectname = y
    //       }
    //     })
    //   }
    // },
    addbkRow() {
      this.broker.push({
        broker_id: "",
        brokermobile: "",
        brokeragetype: "",
        typecalculation: "",
        brokerageamount: "",
      });
    },
    removebkRow(index) {
      this.broker.splice(index, 1);
    },
    addfrRow() {
      this.farmer.push({
        farmer_id: "",
        farmername: "",
        farmerhusbandname: "",
        farmeraadharno: "",
        farmerpanno: "",
        farmermobile: "",
      });
    },
    removefrRow(index) {
      this.farmer.splice(index, 1);
      this.farmervaliation.splice(index, 1);
    },
    addbrRow() {
      this.buyer.push({
        buyer_id: "",
        buyerhusbandname: "",
        buyeraadharno: "",
        buyerpanno: "",
        buyermobile: "",
      });
    },
    removebrRow(index) {
      this.buyer.splice(index, 1);
      this.buyervalidation.splice(index, 1);
    },
    addnaRow() {
      this.naprocess.push({
        naactivity: "",
        nauploadfile: "",
        napending: "",
        naremarks: "",
      });
    },
    removenaRow(index) {
      this.naprocess.splice(index, 1);
    },
    addsdRow() {
      this.saledeed.push({
        documentation: "",
        documentationremarks: "",
        processtracking: "",
        processtrackingremarks: "",
        finalbuyername: "",
        finalbuyerremarks: "",
      });
    },
    removesdRow(index) {
      this.saledeed.splice(index, 1);
    },
    async init() {
      this.getFarmer();
      this.getBroker();
      this.getBuyer();
    },
    async getBuyer(id) {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/getbuyers`,
      };
      await axios(requestOptions)
        .then((response) => {
          this.buyerOption = response.data.data;
          if (id !== undefined) {
            this.buyerOption.map((item) => {
              if (item.id == id) {
                this.broker_id = item;
              }
            });
          }
        })
        .catch((error) => console.log(error, "error"));
    },
    async getBroker(id) {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/getbrokers`,
      };
      await axios(requestOptions)
        .then((response) => {
          this.brokerOption = response.data.data;
          if (id !== undefined) {
            this.brokerOption.map((item) => {
              if (item.id == this.id) {
                this.broker_id = item;
              }
            });
          }
        })
        .catch((error) => console.log(error, "error"));
    },

    async getFarmer(id) {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/farmer`,
      };
      await axios(requestOptions)
        .then((response) => {
          this.farmerOption = response.data.data.data;
        })
        .catch((error) => console.log(error, "error"));
    },
    handleBrokerAmount() {
      this.broker.map((item) => {
        item.brokerageamount = isNaN(
          parseFloat(item.typecalculation) * parseFloat(this.totalvalue)
        )
          ? 0
          : parseFloat(item.typecalculation) * parseFloat(this.totalvalue);
      });
    },
    handleValue() {
      this.totalvalue = isNaN(
        (parseFloat(this.rate) * parseFloat(this.quantity)).toFixed(2)
      )
        ? 0
        : (parseFloat(this.rate) * parseFloat(this.quantity)).toFixed(2);
      this.handleAmount();
      this.broker.map((item) => {
        item.brokerageamount = isNaN(
          parseFloat(item.typecalculation) * parseFloat(this.totalvalue)
        )
          ? 0
          : parseFloat(item.typecalculation) * parseFloat(this.totalvalue);
      });
    },
    handleAmount() {
      this.totalamount = isNaN(
        parseFloat(this.quantity) * parseFloat(this.perbigha)
      )
        ? 0
        : (parseFloat(this.quantity) * parseFloat(this.perbigha)).toFixed(2);
    },
    handleBrokrage(index) {
      if (this.broker[index].brokeragetype == "Fix") {
        this.broker[index].brokerageamount =
          parseFloat(this.broker[index].typecalculation) *
          parseFloat(this.totalvalue);
        this.broker[index].brokerageamount = isNaN(
          this.broker[index].brokerageamount
        )
          ? 0
          : this.broker[index].brokerageamount;
      } else if (this.broker[index].brokeragetype == "%") {
        this.broker[index].brokerageamount = (
          parseFloat(this.totalvalue) *
          parseFloat(this.broker[index].typecalculation / 100)
        ).toFixed(2);
        this.broker[index].brokerageamount = isNaN(
          this.broker[index].brokerageamount
        )
          ? 0
          : this.broker[index].brokerageamount;
      }
    },
  },
};
</script>
<style>
@media (min-width: 768px) {
  .responsive {
    overflow-x: scroll;
    flex-flow: row;
  }
}
/* div::-webkit-scrollbar {
  display: none;
} */

</style>
